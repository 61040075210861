import { Image } from "@components/Image";
import { InfoBanner } from "@components/InfoBanner";
import { Svg } from "@components/Svg";
import { APITypes } from "@lib/api.types";
import { getLocalizedPath } from "@lib/routing/routing.util";
import { DEFAULT_LANGUAGE, SupportedLocales } from "lib/localization";
import { TFunction } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import useGetNavigationBar from "./useGetNavigationBar";

type DesktopNavigationProps = {
  translate: TFunction;
  showInfoBanner?: boolean;
};

const infoBannerProps: APITypes.Components.InfoBannerProps = {
  text: "We're partnering with Start It @ KBC, Belgium's top startup accelerator",
  button: {
    text: "Read More",
    action: APITypes.Components.ButtonActions.GotoExternalLink,
    color: "black",
    external_link: "https://www.pandapanda.be/blog/startitkbc",
  },
};

const DesktopNavigation = ({
  translate,
  showInfoBanner = false,
}: DesktopNavigationProps) => {
  const router = useRouter();

  const { navigationBarData } = useGetNavigationBar(
    (router.locale ?? DEFAULT_LANGUAGE) as SupportedLocales
  );

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [fade, setFade] = useState(false);

  const toggleMenu = () => {
    setFade(false);

    setMenuIsOpen(!menuIsOpen);

    if (!menuIsOpen) {
      setTimeout(() => setFade(!fade), 1);
    }

    // We remove scroll bar when open nav menu
    const body = document.getElementsByTagName("body")[0];
    const html = document.getElementsByTagName("html")[0];

    if (!menuIsOpen) {
      body.style.overflow = "hidden";
      body.style.position = "relative";
      body.style.height = "100%";
      html.style.position = "relative";
      html.style.overflow = "hidden";
      html.style.height = "100%";
    } else {
      body.style.overflow = "";
      body.style.position = "";
      body.style.height = "";
      html.style.position = "";
      html.style.overflow = "";
      html.style.height = "";
    }
  };

  return (
    <>
      {showInfoBanner && infoBannerProps && !menuIsOpen && (
        <InfoBanner infoBannerProps={infoBannerProps} />
      )}
      <div
        className={`sticky left-0 top-0 z-50 w-full bg-black `}
        style={{
          paddingLeft: "calc(100vw - 100%)",
        }}
      >
        <div className="">
          <div className="px-2 sm:px-12 py-2 sm:py-4 max-w-[1260px] mx-auto  | my-auto">
            <div className="flex items-center justify-between | h-16">
              <Link
                className="active:translate-y-[0.1rem] active:shadow-none hover:scale-105 transition-transform hover:ease-in-out duration-300 hover:drop-shadow-md"
                href={`/${router.locale}`}
              >
                <Svg
                  className={`w-auto h-14 hidden sm:block`}
                  icon="logo"
                  title="PandaPanda"
                  viewBox="0 0 80 21"
                />
                <Svg
                  className={`w-auto h-12 sm:hidden block`}
                  icon="logo-mobile"
                  title="PandaPanda"
                  viewBox="0 0 47 32"
                />
              </Link>
              <div className="flex items-center">
                <>
                  {menuIsOpen ? (
                    <button
                      className="flex  gap-2 items-center text-black bg-white rounded-full p-2 pr-6 pl-6 active:translate-y-[0.1rem] active:shadow-none hover:scale-105 hover:transition hover:ease-in-out hover:duration-300 hover:drop-shadow-md"
                      onClick={toggleMenu}
                    >
                      <span className={`hidden sm:block`}>
                        {` ${translate("close").toUpperCase()}`}
                      </span>
                      <Svg
                        className="w-6 h-6"
                        icon="menu-close"
                        title="Open menu"
                      />
                    </button>
                  ) : (
                    <button
                      className="flex gap-2 items-center text-black bg-white rounded-full p-2 pr-6 pl-6 active:translate-y-[0.1rem] active:shadow-none hover:scale-105 transition-transform hover:ease-in-out duration-300 hover:drop-shadow-md"
                      onClick={toggleMenu}
                    >
                      <span className={`hidden sm:block`}> MENU</span>
                      <Svg className="w-6 h-6" icon="menu" title="Close menu" />
                    </button>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
        {menuIsOpen && (
          <div
            className="
                  mt-16
                  sm:mt-24
                  overflow-auto 
                  fixed inset-0 z-40
                  flex flex-col
                  bg-black
              "
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 max-w-[1160px] | mx-auto px-2 pb-4">
              <div className="flex flex-col">
                <div className="h-12"></div>
                <div className="flex-grow | flex flex-col justify-between | pb-4">
                  <ul className="grid gap-8 sm:gap-16 text-2xl sm:text-5xl font-bold">
                    {navigationBarData != null &&
                      navigationBarData.paths.map((path, i) => {
                        return (
                          <li
                            style={{
                              transitionDelay: `${200 * i}ms`,
                            }}
                            key={path.name}
                            className={`flex items-center | h-8 | transition-colors transform transition-all ease-in ${
                              fade ? "opacity-100" : "opacity-0"
                            }`}
                          >
                            <Link
                              onClick={toggleMenu}
                              className={`hover:bg-greyCold rounded-2xl py-4 px-4`}
                              href={getLocalizedPath(
                                path.path,
                                (router.locale ??
                                  DEFAULT_LANGUAGE) as SupportedLocales
                              )}
                            >
                              {`${translate(path.name.toLowerCase())}`}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              {navigationBarData != null && (
                <div
                  className={`items-center grid gap-2 grid-cols-1 lg:gap-4 mt-4 text-2xl sm:text-3xl font-extrabold transform transition ease-in ${
                    fade ? "opacity-100" : "opacity-0"
                  }`}
                  style={{
                    transitionDelay: `${
                      navigationBarData.paths.length * 200
                    }ms`,
                  }}
                >
                  {navigationBarData.image && (
                    <Image
                      style={{ borderRadius: "16px" }}
                      className="hidden sm:block"
                      image={navigationBarData.image.data.attributes}
                      alt={navigationBarData.image.data.attributes.name}
                    />
                  )}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={navigationBarData.top_card.link}
                    className="rounded-2xl bg-greyCold"
                  >
                    <div className="grid grid-cols-5 gap-2 items-center h-32 pl-2 sm:pl-8">
                      <Svg
                        className="h-14"
                        icon={navigationBarData.top_card.icon}
                        title={navigationBarData.top_card.title}
                      />
                      <div className="grid col-span-3">
                        <div className="">
                          {navigationBarData.top_card.title.toUpperCase()}
                        </div>
                        <div className="text-base font-normal">
                          {navigationBarData.top_card.description}
                        </div>
                      </div>
                    </div>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={navigationBarData.bottom_card.link}
                    className="rounded-2xl bg-greyCold"
                  >
                    <div className="grid grid-cols-5 gap-2 items-center h-32 pl-2 sm:pl-8">
                      <Svg
                        className="h-14"
                        icon={navigationBarData.bottom_card.icon}
                        title={navigationBarData.bottom_card.title}
                      />
                      <div className="grid col-span-3">
                        <div className="">
                          {navigationBarData.bottom_card.title.toUpperCase()}
                        </div>
                        <div className="text-base font-normal">
                          {navigationBarData.bottom_card.description}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { DesktopNavigation };
