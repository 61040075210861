import API from "@lib/fetchData";
import { APITypes } from "lib/api.types";
import NextImage from "next/image";

interface ImageProps {
  className?: string;
  image?: APITypes.Components.Media;
  layout?: "responsive" | "fill";
  alt?: string;
  style?: any;
}

const Image = ({ className, image, layout, alt, style }: ImageProps) => {
  if (!image) {
    return null;
  }

  const { url, width, height, alternativeText } = image;

  const props = {
    className,
    src: `${new API().getBaseURL()}${url}`,
    alt: alt ?? alternativeText,
    width,
    height,
    layout,
    style,
    priority: true,
  };

  if (!width || !height) {
    props.layout = "fill";
  }

  return <NextImage id={"customImage"} {...props} />;
};

export { Image };
