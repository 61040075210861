import { IWithChildren } from "./interfaces";
import { Paths } from "./routing";

export namespace APITypes {
  export type Populate<T> =
    | { [K in keyof Partial<T>]: { populate: any } | "*" }
    | "*";

  export interface StrapiQuery<T> {
    fields?: (keyof T)[];
    populate?: Populate<T>;
    filters?: { [x: string]: any };
    locale?: any;
    pagination?: { page: number; pageSize: number };
  }

  export interface StrapiResult<StrapiData> {
    data: StrapiData;
    meta: {
      pagination: {
        page: number;
        pageCount: number;
        pageSize: number;
        total: number;
      };
    };
  }

  export interface StrapiData<Attributes> {
    id: number;
    attributes: Attributes;
  }

  export interface DefaultAttributes<T> {
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    locale: string;
    localizations: PopulatedRelation<T>;
  }

  export interface PopulatedObject<T> {
    data: StrapiData<T>;
  }

  export interface PopulatedRelation<T> {
    data: StrapiData<T>[];
  }

  export interface Global {
    default_email_address: string;
    default_address: string;
    default_address_link: string;
    company_name: string;
    company_description: string;
    logo: PopulatedObject<Components.Media>;
  }

  export namespace Pages {
    interface Page {
      Hero: Components.Hero;
    }

    export interface Home extends DefaultAttributes<Home>, Page {
      HeroCard: Components.HeroCard[];
      page_content_blocks: Components.ContentBlocks[];
      Contact: Components.Contact;
    }

    export interface AboutUs extends DefaultAttributes<AboutUs>, Page {
      HeroCard: Components.HeroCard[];
      page_content_blocks: Components.ContentBlocks[];
      Contact: Components.Contact;
    }

    export interface FigmaCourses
      extends DefaultAttributes<FigmaCourses>,
        Page {
      HeroCard: Components.HeroCard[];
      page_content_blocks: Components.ContentBlocks[];
      Contact: Components.Contact;
    }
    export interface Services extends DefaultAttributes<Services>, Page {
      HeroCard: Components.HeroCard[];
      TextBlocks: Components.ContentBlocks.TextBlocks;
      IconCards: Components.ContentBlocks.IconCards[];
      Contact: Components.Contact;
    }
    export interface Contact extends DefaultAttributes<Contact>, Page {
      Title: Components.ContentBlocks.Title;
      Contact: Components.Contact;
    }

    export interface Work extends DefaultAttributes<Work>, Page {
      HeroCard: Components.HeroCard[];
      title: string;
      FeaturedCases: {
        id: number;
        cases: PopulatedRelation<Collections.Case>;
      };
      Contact: Components.Contact;
    }
    export interface Blog extends DefaultAttributes<Blog>, Page {
      FeaturedBlogs: {
        id: number;
        blogs: PopulatedRelation<Collections.Blog>;
      };
      Contact: Components.Contact;
    }
  }

  export namespace Collections {
    export interface Case extends DefaultAttributes<Case> {
      title: string;
      name: string;
      logo: PopulatedObject<Components.Media>;
      tags: Components.ContentBlocks.Tag[];
      images: PopulatedRelation<Components.Media>;
      hex_background_color: string;
      text_color: string;
      short_description: string;
      slug: string;
      order_number: number;
      page_content_blocks: Components.ContentBlocks[];
      Contact: Components.Contact;
    }

    export interface Blog extends DefaultAttributes<Blog> {
      image: PopulatedObject<Components.Media>;
      title: string;
      shortDescription: string;
      tags: Components.ContentBlocks.Tag[];
      slug: string;
      contact: Components.Contact;
      page_content_blocks: Components.ContentBlocks[];
    }
    export interface LandingPage extends DefaultAttributes<LandingPage> {
      //TODO: Add the rest of the fields
      Hero: Components.Hero;
      HeroCards: Components.HeroCard[];
      slug: string;
      Blocks: Components.ContentBlocks[];
      services: PopulatedRelation<Collections.Service>;
      contact: Components.Contact;
    }

    export interface TeamMember extends DefaultAttributes<TeamMember> {
      fullName: string;
      title: string;
      url?: string;
      image: PopulatedObject<Components.Media>;
    }

    export interface Service extends DefaultAttributes<Service> {
      title: string;
      description: string;
      image: PopulatedObject<Components.Media>;
      actions: Components.Button[];
    }

    export interface FigmaTraining extends DefaultAttributes<FigmaTraining> {
      image: PopulatedObject<Components.Media>;
      title: string;
      description: string;
      list?: { title: string; value: string }[];
      button: Components.Button;
      isBottomCard: boolean;
    }
  }

  export namespace Components {
    export type ButtonColors = "orange" | "purple" | "white" | "black";
    export type TagColors = "orange" | "purple" | "white" | "black";
    export type BackgroundColor = "orange" | "purple" | "white";
    export type IconColor = "orange" | "purple" | "white";
    export type HeroCardBackgroundColor =
      | "lightPurple"
      | "lightOrange"
      | "wheat";
    export type HeroCardWidth = "full" | "half1" | "half2" | "p60" | "p40";
    export type WidthHeroCard = "orange" | "purple | wheat";

    export interface ButtonWrapperProps extends IWithChildren {
      button: Button;
      size?: "slim" | "small" | "large";
      icon?: string;
      fill: boolean;
      fullWidth?: boolean;
    }

    export interface InfoBannerProps {
      text: string;
      button?: Button;
    }

    export enum ButtonActions {
      GotoHome = "goto:home",
      GotoServices = "goto:services",
      GotoWork = "goto:work",
      GotoContact = "goto:contact",
      GotoAboutUs = "goto:about_us",
      GotoExternalLink = "goto:external_link",
      ActionScrollDown = "action:scroll_down",
      ActionScrollToForm = "action:scroll_to_form",
    }
    export type IllustrationPosition = "left" | "right";

    export type Icon = "collaboration" | "connection" | "rocket" | "speed";

    export type SocialIcon = "linkedin" | "twitter" | "instagram" | "website";

    export type TextBlockIcon = "loop" | "book" | "shape" | "build";

    export interface Media {
      name: string;
      alternativeText: string;
      caption: string;
      width: number;
      height: number;
      formats: any[];
      hash: string;
      ext: string;
      mime: string;
      size: number;
      url: string;
      previewUrl?: null | string;
      provider: string;
      provider_metadata: string | null;
      createdAt: string;
      updatedAt: string;
    }

    export interface NavigationBar {
      top_card: SocialCard;
      bottom_card: SocialCard;
      image?: PopulatedObject<Media>;
      paths: LinkNavBar[];
    }

    export interface Hero {
      title: string;
      description?: string;
      button?: Button[];
      background_image?: PopulatedRelation<Media>;
      // below properties we use for detail work page
      logo?: PopulatedObject<Media>;
      name?: string;
      tags?: Components.ContentBlocks.Tag[];
    }
    export interface HeroCard {
      text: string;
      background_color: HeroCardBackgroundColor;
      width: HeroCardWidth;
      logo?: PopulatedRelation<Media>;
    }
    export interface Contact {
      title: string;
      image: PopulatedObject<Media>;
      social_cards: SocialCard[];
      left_card: SocialCard;
      right_card: SocialCard;
    }

    export interface Button {
      text: string;
      color: ButtonColors;
      action: ButtonActions;
      external_link?: string | null;
    }

    export interface SocialCard {
      title: string;
      link: string;
      icon: string;
      description?: string;
    }

    export interface LinkNavBar {
      name: string;
      path: Paths;
    }

    export type ContentBlocks =
      | ContentBlocks.TextWithImage
      | ContentBlocks.Button
      | ContentBlocks.Title
      | ContentBlocks.IllustrationBlock
      | ContentBlocks.IconCards
      | ContentBlocks.Images
      | ContentBlocks.TextBlocks
      | ContentBlocks.IllustrationBlocks
      | ContentBlocks.CaseInformationBlock
      | ContentBlocks.CaseNavigationBlock
      | ContentBlocks.CaseQuoteBlock
      | ContentBlocks.CaseBlock
      | ContentBlocks.FeaturedTeam
      | ContentBlocks.SocialButtons
      | ContentBlocks.ImageBlock
      | ContentBlocks.VideoBlock
      | ContentBlocks.RichtextBlock
      | ContentBlocks.FeaturedFigmaTrainings
      | ContentBlocks.FeaturedFigmaTrainings
      | ContentBlocks.FullWidthButtonBlock
      | ContentBlocks.HeroCard
      | ContentBlocks.Tetten
      | ContentBlocks.OfferedServices;

    export namespace ContentBlocks {
      export enum Types {
        Title = "content-blocks.title",
        TextWithImage = "content-blocks.text",
        Button = "content-blocks.button",
        IllustrationBlock = "content-blocks.illustration-block",
        IconCards = "content-blocks.icon-cards",
        Images = "content-blocks.images",
        TextBlocks = "content-blocks.blocks-text",
        IllustrationBlocks = "content-blocks.illustration-blocks",
        CaseInformationBlock = "content-blocks.case-information-block",
        CaseNavigationBlock = "content-blocks.navigation-case-block",
        CaseQuoteBlock = "content-blocks.case-quote-block",
        CaseBlock = "content-blocks.case-block",
        FeaturedTeam = "page.featured-team",
        SocialButtons = "content-blocks.buttons_social",
        ImageBlock = "content-blocks.image-block",
        VideoBlock = "content-blocks.video-block",
        RichtextBlock = "content-blocks.rich-text-block",
        FeaturedFigmaTrainings = "page.featured-figma-trainings",
        FullWidthButtonBlock = "content-blocks.full-width-button-block",
        HeroCard = "content-blocks.hero-card",
        Tetten = "content-blocks.tetten",
        OfferedServices = "content-blocks.offered-services",
      }

      interface BaseProps {
        id: number;
        __component: Types;
      }

      export interface TextWithImage extends BaseProps {
        title: string;
        subtitle: string;
        text: string;
        background_image: PopulatedObject<Media>;
        button: Button;
      }

      export interface Button extends BaseProps {
        text: string;
        color: ButtonColors;
        action: ButtonActions;
        external_link?: string;
      }
      export interface TextBlock extends BaseProps {
        title: string;
        icon?: TextBlockIcon;
        text: string;
        tag: Tag[];
        button: Button;
      }

      export interface SocialButton extends BaseProps {
        title: string;
        subTitle: string;
        hexColor: string;
        url: string;
        icon: PopulatedObject<Media>;
      }

      export interface Title extends BaseProps {
        title: string;
        description?: string;
      }

      export interface Tag extends BaseProps {
        title: string;
        color: TagColors;
      }

      export interface IllustrationBlock extends BaseProps {
        illustration: PopulatedObject<Media>;
        position: IllustrationPosition;
        text_block: TextBlock;
        button: Button;
      }

      interface IconCard {
        title: string;
        icon: Icon;
        icon_color: IllustrationPosition;
        background_color: IconColor;
        span: string;
      }

      export interface IconCards extends BaseProps {
        title: string;
        icon_cards: IconCard[];
      }

      export interface Images extends BaseProps {
        images: PopulatedRelation<Media>;
      }

      export interface TextBlocks extends BaseProps {
        text_blocks: TextBlock[];
      }

      export interface SocialButtons extends BaseProps {
        social_buttons: SocialButton[];
      }
      export interface IllustrationBlocks extends BaseProps {
        title: string;
        illustration_blocks: IllustrationBlock[];
      }

      export interface TitleWithLogo extends BaseProps {
        title: string;
        logo: "type" | "people" | "location" | "link";
      }

      export interface CaseInformationCard extends BaseProps {
        title_with_logo: TitleWithLogo[];
      }

      export interface CaseInformationBlock extends BaseProps {
        case_information_card: CaseInformationCard;
        description: string;
      }

      export interface CaseNavigationBlock extends BaseProps {
        text_with_image: TextWithImage[];
      }
      export interface CaseQuoteBlock extends BaseProps {
        image: PopulatedObject<Media>;
        quote: string;
        author: string;
        hex_background_color: string;
        button: Button;
      }
      export interface CaseBlock extends BaseProps {
        FeaturedCases: {
          id: number;
          cases: PopulatedRelation<Collections.Case>;
        };
      }
      export interface FeaturedTeam extends BaseProps {
        id: number;
        team_members: PopulatedRelation<Collections.TeamMember>;
      }

      export interface FeaturedFigmaTrainings extends BaseProps {
        id: number;
        trainings: PopulatedRelation<Collections.FigmaTraining>;
      }

      export interface ImageBlock extends BaseProps {
        image: PopulatedObject<Media>;
      }
      export interface VideoBlock extends BaseProps {
        video: PopulatedObject<Media>;
      }
      export interface RichtextBlock extends BaseProps {
        richtext: string;
      }

      export interface FullWidthButtonBlock extends BaseProps {
        button: Button;
      }

      export interface HeroCard extends BaseProps {
        text: string;
        background_color: HeroCardBackgroundColor;
        width: HeroCardWidth;
        logo?: PopulatedRelation<Media>;
      }

      export interface Tetten extends BaseProps {
        title: string;
        blocks: ServiceBlock[];
      }

      export interface OfferedServices extends BaseProps {
        title: string;
        Offers: ServiceBlock[];
      }

      export interface ServiceBlock extends BaseProps {
        title: string;
        description: string;
        image?: PopulatedObject<Media>;
        action?: Button;
      }
    }
  }
}
