export const NEXT_LOCALE_COOKIE = "NEXT_LOCALE";

import {
  NL_ABOUT_US,
  NL_CONTACT,
  NL_SERVICES,
  NL_WORK,
  NL_COOKIE_POLICY,
  NL_MANAGE_COOKIES,
  NL_PRIVACY_POLICY,
  NL_BLOG,
  NL_FIGMA_COURSES,
} from "./rewrites";

export enum Paths {
  Home = "/",
  AboutUs = "/about-us",
  FigmaCourses = "/figma-courses",
  Contact = "/contact",
  Services = "/services",
  Work = "/work",
  PrivacyPolicy = "/privacy-policy",
  CookiePolicy = "/cookie-policy",
  ManageCookies = "/manage-cookies",
  Blog = "/blog",
}
//Path to translation
export const pathToNlStringMap = new Map<Paths, string>([
  [Paths.Home, "/"],
  [Paths.AboutUs, `/${NL_ABOUT_US}`],
  [Paths.FigmaCourses, `/${NL_FIGMA_COURSES}`],
  [Paths.Contact, `/${NL_CONTACT}`],
  [Paths.Services, `/${NL_SERVICES}`],
  [Paths.Work, `/${NL_WORK}`],
  [Paths.PrivacyPolicy, `/${NL_PRIVACY_POLICY}`],
  [Paths.CookiePolicy, `/${NL_COOKIE_POLICY}`],
  [Paths.ManageCookies, `/${NL_MANAGE_COOKIES}`],
  [Paths.Blog, `/${NL_BLOG}`],
]);

export const pathToEnStringMap = new Map<Paths, string>([
  [Paths.Home, "/"],
  [Paths.AboutUs, "/about-us"],
  [Paths.FigmaCourses, "/figma-courses"],
  [Paths.Contact, "/contact"],
  [Paths.Services, "/services"],
  [Paths.Work, "/work"],
  [Paths.PrivacyPolicy, "/privacy-policy"],
  [Paths.CookiePolicy, "/cookie-policy"],
  [Paths.ManageCookies, "/manage-cookies"],
  [Paths.Blog, "/blog"],
]);

//translation to path
export const nlStringToPathsMap = new Map<string, Paths>([
  ["/", Paths.Home],
  [`/${NL_ABOUT_US}`, Paths.AboutUs],
  [`/${NL_FIGMA_COURSES}`, Paths.FigmaCourses],
  [`/${NL_CONTACT}`, Paths.Contact],
  [`/${NL_SERVICES}`, Paths.Services],
  [`/${NL_WORK}`, Paths.Work],
  [`/${NL_PRIVACY_POLICY}`, Paths.PrivacyPolicy],
  [`/${NL_COOKIE_POLICY}`, Paths.CookiePolicy],
  [`/${NL_MANAGE_COOKIES}`, Paths.ManageCookies],
  [`/${NL_BLOG}`, Paths.Blog],
]);

export const enStringToPathsMap = new Map<string, Paths>([
  ["/", Paths.Home],
  ["/about-us", Paths.AboutUs],
  ["/figma-courses", Paths.FigmaCourses],
  ["/contact", Paths.Contact],
  ["/services", Paths.Services],
  ["/work", Paths.Work],
  ["/privacy-policy", Paths.PrivacyPolicy],
  ["/cookie-policy", Paths.CookiePolicy],
  ["/manage-cookies", Paths.ManageCookies],
  ["/blog", Paths.Blog],
]);
