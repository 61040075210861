import DOMPurify from "isomorphic-dompurify";
import { parse } from "node-html-parser";

interface ISanitizeString {
  text: string;
}

export const SanitizeString = ({ text }: ISanitizeString) => {
  const cleanText = DOMPurify.sanitize(text, {
    ALLOWED_TAGS: [
      "p",
      "br",
      "&nbsp;",
      "purple",
      "orange",
      "khaki",
      "b",
      "h",
      "iframe",
      "a",
    ],
  });
  const textParse = parse(cleanText);
  const aTags = textParse.querySelectorAll("a");
  aTags.forEach((aTag) => {
    aTag.setAttribute("target", "_blank");
  });

  return (
    <div
      className="[&>purple]:text-purple [&>h]:text-2xl [&>h]:font-bold [&>b]:font-bold [&>orange]:text-orange [&>khaki]:text-khaki [&>a]:underline "
      dangerouslySetInnerHTML={{
        __html: textParse.toString(),
      }}
    />
  );
};
