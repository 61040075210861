export const fetcher = async <T>(
  input: RequestInfo,
  init: RequestInit
): Promise<T> => {
  const res = await fetch(input, {
    ...init,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_WEBSITE_API_TOKEN}`,
    },
  });
  return res.json();
};
