import { APITypes } from "lib/api.types";

interface ITagBlockProps {
  block: APITypes.Components.ContentBlocks.Tag[];
}

const TagBlock = ({ block }: ITagBlockProps) => {
  const classes = (color: string) =>
    [
      "inline-flex items-center",
      "border-2",
      "rounded-lg",
      "pt-2",
      "pb-2",
      "px-4",
      "py-4",
      "font-black",
      "border-inherit",
      color === "orange" &&
        "text-orange bg-transparent border-current border-orange",
      color === "purple" &&
        "text-purple bg-transparent border-current border-purple",
      color === "white" &&
        "text-white bg-transparent border-current border-white",
      "text-xs",
    ]
      .filter(Boolean)
      .join(" ");

  return (
    <div
      className="flex flex-wrap md:order-1 | md:mt-2 gap-2"
      style={{ borderColor: "inherit" }}
    >
      {block.map((tag, index) => (
        <div key={index} className={classes(tag.color)}>
          {tag.title.toUpperCase()}
        </div>
      ))}
    </div>
  );
};

export default TagBlock;
