//NL
const NL_ABOUT_US = "over-ons";
const NL_FIGMA_COURSES = "figma-opleidingen";
const NL_CONTACT = "contact";
const NL_SERVICES = "diensten";
const NL_WORK = "werk";
const NL_PRIVACY_POLICY = "privacy-policy";
const NL_COOKIE_POLICY = "cookie-policy";
const NL_MANAGE_COOKIES = "manage-cookies";
const NL_BLOG = "blog";

const paths = {
  ["about-us"]: {
    nl: NL_ABOUT_US,
  },
  ["figma-courses"]: {
    nl: NL_FIGMA_COURSES,
  },
  ["services"]: {
    nl: NL_SERVICES,
  },
  ["work"]: {
    nl: NL_WORK,
  },
  ["blog"]: {
    nl: NL_BLOG,
  },
  ["privacy-policy"]: {
    nl: NL_PRIVACY_POLICY,
  },
  ["cookie-policy"]: {
    nl: NL_COOKIE_POLICY,
  },
  contact: {
    nl: NL_CONTACT,
  },
};

const getRewrites = () => {
  const nlRewrites = Object.keys(paths).map((path) => ({
    source: `/${paths[path].nl}/:slug*`,
    destination: `/${path}/:slug*`,
  }));
  return [...nlRewrites];
};

module.exports = {
  NL_ABOUT_US,
  NL_CONTACT,
  NL_SERVICES,
  NL_COOKIE_POLICY,
  NL_PRIVACY_POLICY,
  NL_MANAGE_COOKIES,
  NL_WORK,
  NL_BLOG,
  NL_FIGMA_COURSES,
  paths,
  getRewrites,
};
