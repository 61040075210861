import { IWithChildren } from "lib/interfaces";

export interface BlockProps extends IWithChildren {
  width?: "xs" | "sm" | "md" | "full" | "breakout";
  id?: string;
}

const Block = ({ width = "md", children, id }: BlockProps) => {
  const classes = [
    "w-full",
    "mx-auto",
    width === "xs" && "max-w-[800px]",
    width === "sm" && "max-w-[1032px]",
    width === "md" && "max-w-[1160px]",
    width === "full" && "max-w-[1360px]",
    width === "breakout" &&
      "max-w-[1360px] w-screen | overflow-hidden | -mx-6 sm:-mx-10 md:w-full md:mx-auto",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div id={id} className={classes}>
      {children}
    </div>
  );
};

export { Block };
