type IconDefinition = {
  className: string;
  icon: string;
  title: string;
  viewBox?: string;
};

const Svg = ({ className = "", icon, title, viewBox }: IconDefinition) => {
  return (
    <svg className={`${className} | fill-current w-full`} viewBox={viewBox}>
      <title>{title}</title>
      <use xlinkHref={`#svg-${icon}`} />
    </svg>
  );
};

export { Svg };
