import Head from "next/head";

export interface IMeta {
  title: string;
}

export function Meta({ title }: IMeta) {
  return (
    <Head>
      <title>{title}</title>
      <link rel="shortcut icon" href="/favicon.ico" />
      <meta
        name="description"
        property="og:description"
        content="Digital products are an essential part of our daily life. Unfortunately, many of them aren't built right. Many of them aren't even human-friendly. We are changing this by building damn good digital products that last."
      />
      <meta
        name="title"
        property="og:title"
        content="PandaPanda | Damn good digital products, built to last"
      />
      <meta
        name="description"
        content="Digital products are an essential part of our daily life. Unfortunately, many of them aren't built right. Many of them aren't even human-friendly. We are changing this by building damn good digital products that last."
      />
      <meta
        name="title"
        content="PandaPanda | Damn good digital products, built to last"
      />
      <meta
        name="viewport"
        content="width=device-width,height=device-height,initial-scale=1,maximum-scale=1"
      />
      <meta
        property="og:image"
        content={`${process.env.NEXT_PUBLIC_STRAPI_URL}/uploads/OG_Image_2351b15674.png?updated_at=2023-03-03T13:58:28.889Z`}
      />

      <meta
        name="twitter:title"
        content="PandaPanda | Damn good digital products, built to last"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:domain" content="https://www.pandapanda.be/" />
      <meta name="twitter:url" content="https://www.pandapanda.be/" />
      <meta name="twitter:creator" content="@PandaPandaBEL" />
      <meta name="twitter:site" content="@PandaPandaBEL" />
      <meta
        name="twitter:image"
        content={`${process.env.NEXT_PUBLIC_STRAPI_URL}/uploads/OG_Image_Twitter_4f83686625.png?updated_at=2023-03-03T13:58:28.699Z`}
      />
      <meta
        name="twitter:description"
        content="Digital products are an essential part of our daily life. Unfortunately, many of them aren't built right. Many of them aren't even human-friendly. We are changing this by building damn good digital products that last."
      />
    </Head>
  );
}
