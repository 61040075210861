export const supportedLocales = ["nl", "en"] as const;
export type SupportedLocales = (typeof supportedLocales)[number] | "all";

export interface IAvailableLanguage {
  value: SupportedLocales;
  label: string;
  url: string;
  flag: string;
}

export const availableLanguages: IAvailableLanguage[] = [
  { value: "nl", label: "Nederlands", url: "/nl", flag: "nl" },
  { value: "en", label: "English", url: "/en", flag: "en" },
];

export const DEFAULT_LANGUAGE = availableLanguages[1]; // "en";
