import qs from "qs";

import { APITypes } from "./api.types";
import { SupportedLocales } from "./localization";

export enum Endpoints {
  Homepage = "home-page",
  NavigationBar = "navigation-bar",
  Global = "global",
  ContactPage = "contact-page",
  AboutUsPage = "about-page",
  FigmaCoursesPage = "courses-page",
  ServicesPage = "services-page",
  WorkPage = "work-page",
  Cases = "cases",
  BlogPage = "blog-page",
  Blogs = "blogs",
  FigmaTrainings = "figma-trainings",
  LandingPages = "landing-pages",
}

type Environments = "local" | "development" | "production" | "test";

export default class API {
  private fetchNavigationProps;
  private fetchGlobalProps;

  constructor(private locale: SupportedLocales = "all") {
    this.fetchNavigationProps = {
      populate: {
        paths: { populate: "*" },
        top_card: { populate: "*" },
        bottom_card: { populate: "*" },
        image: { populate: "*" },
      },
      locale: this.locale,
    };
    this.fetchGlobalProps = {
      populate: { logo: { populate: "*" } },
      locale: this.locale,
    };
  }

  fetchNavigationURL(): string {
    return this.getURL(Endpoints.NavigationBar, this.fetchNavigationProps);
  }

  fetchGlobalURL(): string {
    return this.getURL(Endpoints.Global, this.fetchGlobalProps);
  }

  async fetchHomePage() {
    return this.getSingle<APITypes.Pages.Home>(Endpoints.Homepage, {
      populate: {
        Hero: { populate: "*" },
        HeroCard: { populate: "*" },
        page_content_blocks: {
          populate: {
            tag: "*",
            illustration: "*",
            text_block: {
              populate: "*",
            },
            background_image: "*",
            button: "*",
            icon_cards: "*",
            FeaturedCases: { populate: { cases: { populate: "*" } } },
            textWithImage: { populate: { background_image: "*" } },
          },
        },
        Contact: {
          populate: {
            image: "*",
            social_cards: "*",
            left_card: "*",
            right_card: "*",
          },
        },
      },
      locale: this.locale,
    });
  }

  async fetchAboutUsPage() {
    return this.getSingle<APITypes.Pages.AboutUs>(Endpoints.AboutUsPage, {
      populate: {
        Hero: { populate: "*" },
        HeroCard: { populate: "*" },
        page_content_blocks: {
          populate: {
            tag: "*",
            illustration: "*",
            text_block: {
              populate: {
                button: "*",
                tag: "*",
              },
            },
            text_blocks: {
              populate: {
                button: "*",
                tag: "*",
              },
            },
            text_with_image: {
              populate: {
                background_image: "*",
              },
            },
            team_members: {
              populate: {
                image: "*",
              },
            },
            social_buttons: {
              populate: {
                icon: "*",
              },
            },
            background_image: "*",
            button: "*",
            icon_cards: "*",
            images: "*",
          },
        },
        Contact: {
          populate: {
            image: "*",
            social_cards: "*",
            left_card: "*",
            right_card: "*",
          },
        },
      },
      locale: this.locale,
    });
  }

  async fetchFigmaCoursesPage() {
    return this.getSingle<APITypes.Pages.FigmaCourses>(
      Endpoints.FigmaCoursesPage,
      {
        populate: {
          Hero: { populate: "*" },
          HeroCard: { populate: "*" },
          page_content_blocks: {
            populate: {
              title: "*",
              team_members: {
                populate: {
                  image: "*",
                },
              },
              trainings: {
                populate: {
                  image: "*",
                  button: "*",
                  list: "*",
                },
              },
            },
          },
          Contact: {
            populate: {
              image: "*",
              social_cards: "*",
              left_card: "*",
              right_card: "*",
            },
          },
        },
        locale: this.locale,
      }
    );
  }

  async fetchServicesPage() {
    return this.getSingle<APITypes.Pages.Services>(Endpoints.ServicesPage, {
      populate: {
        Hero: { populate: "*" },
        HeroCard: { populate: "*" },
        TextBlocks: {
          populate: {
            text_blocks: {
              populate: {
                button: "*",
                tag: "*",
              },
            },
          },
        },
        IconCards: { populate: "*" },
        Contact: {
          populate: {
            image: "*",
            social_cards: "*",
            left_card: "*",
            right_card: "*",
          },
        },
      },
      locale: this.locale,
    });
  }

  async fetchWorkPage() {
    return this.getSingle<APITypes.Pages.Work>(Endpoints.WorkPage, {
      populate: {
        Hero: { populate: "*" },
        HeroCard: { populate: "*" },
        FeaturedCases: { populate: { cases: { populate: "*" } } },
        Contact: {
          populate: {
            image: "*",
            social_cards: "*",
            left_card: "*",
            right_card: "*",
          },
        },
      },
      locale: this.locale,
    });
  }

  async fetchBlogPage() {
    return this.getSingle<APITypes.Pages.Blog>(Endpoints.BlogPage, {
      populate: {
        Hero: { populate: "*" },
        FeaturedBlogs: {
          populate: { blogs: { populate: "*", sort: "createdAt:DESC" } },
        },
        Contact: {
          populate: {
            image: "*",
            social_cards: "*",
            left_card: "*",
            right_card: "*",
          },
        },
      },
      locale: this.locale,
    });
  }

  async fetchContactPage() {
    return this.getSingle<APITypes.Pages.Contact>(Endpoints.ContactPage, {
      populate: {
        Hero: { populate: "*" },
        Title: { populate: "*" },
        Contact: {
          populate: {
            image: "*",
            social_cards: "*",
            left_card: "*",
            right_card: "*",
          },
        },
      },
      locale: this.locale,
    });
  }

  async fetchAllCases() {
    return this.getCollection<APITypes.Collections.Case>(Endpoints.Cases, {
      populate: "*",
      locale: this.locale,
    });
  }

  async fetchAllBlogs() {
    return this.getCollection<APITypes.Collections.Blog>(Endpoints.Blogs, {
      populate: "*",
      locale: this.locale,
    });
  }

  async fetchAllLandingPages() {
    return this.getCollection<APITypes.Collections.LandingPage>(
      Endpoints.LandingPages,
      {
        populate: "*",
        locale: this.locale,
      }
    );
  }

  fetchCaseBySlug = (slug: string) =>
    this.fetchBySlug<APITypes.Collections.Case>(Endpoints.Cases, slug, {
      logo: "*",
      images: "*",
      tags: "*",
      localizations: "*",
      page_content_blocks: {
        populate: {
          case_information_card: {
            populate: {
              title_with_logo: "*",
            },
          },
          text_with_image: {
            populate: {
              background_image: "*",
            },
          },
          button: "*",
          image: "*",
        },
      },
      Contact: {
        populate: {
          image: "*",
          social_cards: "*",
          left_card: "*",
          right_card: "*",
        },
      },
    });

  fetchBlogBySlug = (slug: string) =>
    this.fetchBySlug<APITypes.Collections.Blog>(Endpoints.Blogs, slug, {
      title: "*",
      image: "*",
      tags: "*",
      localizations: "*",
      page_content_blocks: {
        populate: {
          video: "*",
          image: "*",
          button: "*",
        },
      },
      contact: {
        populate: {
          image: "*",
          social_cards: "*",
          left_card: "*",
          right_card: "*",
        },
      },
    });

  fetchLandingPageBySlug = (slug: string) =>
    this.fetchBySlug<APITypes.Collections.LandingPage>(
      Endpoints.LandingPages,
      slug,
      {
        Hero: {
          populate: "*",
        },
        HeroCards: {
          populate: {
            logo: "*",
          },
        },
        services: {
          populate: "*",
        },
        Blocks: {
          populate: {
            tag: "*",
            illustration: "*",
            text_block: {
              populate: {
                button: "*",
                tag: "*",
              },
            },
            text_blocks: {
              populate: {
                button: "*",
                tag: "*",
              },
            },
            text_with_image: {
              populate: {
                background_image: "*",
              },
            },
            team_members: {
              populate: {
                image: "*",
              },
            },
            social_buttons: {
              populate: {
                icon: "*",
              },
            },
            background_image: "*",
            button: "*",
            icon_cards: "*",
            images: "*",
            image: "*",
            FeaturedCases: { populate: { cases: { populate: "*" } } },
          },
        },
        contact: {
          populate: {
            image: "*",
            social_cards: "*",
            left_card: "*",
            right_card: "*",
          },
        },
      }
    );

  private async fetchBySlug<T>(
    endpoint: Endpoints,
    slug: string,
    populate: APITypes.Populate<T> = "*"
  ) {
    return this.getCollection<T>(endpoint, {
      populate,
      filters: { slug: { $eq: slug } },
      locale: this.locale,
    });
  }

  async getSingle<T = any>(
    path: string,
    options?: APITypes.StrapiQuery<T>
  ): Promise<APITypes.StrapiResult<APITypes.StrapiData<T>>> {
    return this.get<T, APITypes.StrapiResult<APITypes.StrapiData<T>>>(
      path,
      options
    );
  }

  async getCollection<T = any>(
    path: string,
    options?: APITypes.StrapiQuery<T>
  ): Promise<APITypes.StrapiResult<APITypes.StrapiData<T>[]>> {
    return this.get<T, APITypes.StrapiResult<APITypes.StrapiData<T>[]>>(
      path,
      options
    );
  }

  private constructQuery<T>({
    fields,
    populate,
    filters,
    locale,
    pagination,
  }: APITypes.StrapiQuery<T>): string {
    let queryObject: APITypes.StrapiQuery<T> = {};

    if (populate) queryObject.populate = populate;
    if (filters) queryObject.filters = filters;
    if (fields) queryObject.fields = fields;
    if (locale) queryObject.locale = locale;
    if (pagination) queryObject.pagination = pagination;

    return qs.stringify(queryObject, {
      encodeValuesOnly: true,
    });
  }

  getBaseURL = () => {
    const env = (process.env.NEXT_PUBLIC_NODE_ENV ||
      process.env.NODE_ENV) as Environments;
    let baseURL = process.env.NEXT_PUBLIC_STRAPI_URL;
    if (env === "local") {
      baseURL = process.env.NEXT_PUBLIC_LOCAL_STRAPI_URL;
    }

    return baseURL;
  };

  getBaseAPIURL = () => {
    const env = (process.env.NEXT_PUBLIC_NODE_ENV ||
      process.env.NODE_ENV) as Environments;

    let baseURL = process.env.NEXT_PUBLIC_STRAPI_API_URL;
    if (env === "local") {
      baseURL = process.env.NEXT_PUBLIC_LOCAL_STRAPI_API_URL;
    }

    return baseURL;
  };

  private getURL<T>(path: string, options?: APITypes.StrapiQuery<T>): string {
    let query;
    if (options) {
      query = this.constructQuery<T>(options);
    }
    let url = `${this.getBaseAPIURL()}/${path}`;

    if (query) url = `${url}?${query}`;

    return url;
  }

  private async get<T, R>(
    path: string,
    options?: APITypes.StrapiQuery<T>
  ): Promise<R> {
    const url = this.getURL(path, options);
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_WEBSITE_API_TOKEN}`,
      },
    });

    const json = await res.json();
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }

    return json;
  }
}
