import { ButtonWrapper } from "@components/Button";
import { APITypes } from "@lib/api.types";
import Link from "next/link";

interface InfoBannerProps {
  infoBannerProps: APITypes.Components.InfoBannerProps;
}

const InfoBanner = ({ infoBannerProps }: InfoBannerProps) => {
  const { button, text } = infoBannerProps;

  return (
    <div className="sticky text-black bg-[wheat] flex py-4 px-6 flex-col md:flex-row justify-center align-center gap-4">
      <span
        className="text-center md:text-left text-base md:text-lg"
        style={{ margin: "auto 0" }}
      >
        {text}
        {button?.external_link && (
          <Link
            className="ml-1 lg:hidden underline text-base md:text-lg"
            href={button.external_link}
            target="_blank"
            rel="moopener noreferrer"
          >
            Read More
          </Link>
        )}
      </span>
      {button && (
        <div className={`hidden lg:flex`}>
          <ButtonWrapper
            fill={false}
            size="slim"
            button={{
              text: button.text,
              action: button.action,
              color: button.color,
              external_link: button.external_link,
            }}
            fullWidth={false}
          />
        </div>
      )}
    </div>
  );
};

export { InfoBanner };
